import React, { useReducer, useState } from "react";
import axios from "axios";
import { validForm } from "../../Hooks/validForm";
import { useQuery } from "@tanstack/react-query";
import utilities, {
  GetResponseWnds,
  clrFldsExcptDrpDt,
  createGetApi,
  dataStr_ToArray,
  svLnxSrvr,
} from "../../utilities/utilities";
import { Button, SvgIcon } from "@mui/material";
import NrjAgGrid from "../../components/reusable/NrjAgGrid";
import { useNrjAxios } from "../../Hooks/useNrjAxios";

import { getFldValue } from "../../Hooks/useGetFldValue";
import { useEffectOnce } from "react-use";
import WtrInput from "../../components/reusable/nw/WtrInput";
import { Toaster } from "../../components/reusable/Toaster";
import NrjMatChp from "../../components/reusable/NrjMatChp";
import { useNavigate } from "react-router";

const ACTIONS = {
  TRIGGER_GRID: "grdtrigger",
  NEWROWDATA: "newrow",
  RANDOM: "rndm",
  TRIGGER_FORM: "trgfrm",
  FORM_DATA: "frmdata",
  SETFORM_DATA: "setfrmdata",
  MAINID: "mnid",
  CHECK_REQ: "chckreq",
  CHECK_REQDONE: "chckreqdn",
  SETGID: "gd",
  NEWFRMDATA: "frmdatanw",
};

const initialState = {
  triggerG: 0,
  nwRow: [],
  rndm: 1,
  trigger: 0,
  textDts: "",
  mainId: 0,
  errMsg: [],
  openDrwr: false,
  frmData: "",
  gid: "",
};

type purBill = {
  triggerG: number;
  nwRow: any;
  rndm: number;
  trigger: number;
  textDts: string;
  mainId: number;
  errMsg: any;
  openDrwr: boolean;
  frmData: string;
  gid: string;
};

type act = {
  type: string;
  payload: any;
};

const reducer = (state: purBill, action: act) => {
  let newstate: any = { ...state };
  switch (action.type) {
    case ACTIONS.NEWFRMDATA:
      return newstate;
    case ACTIONS.MAINID:
      newstate.mainId = action.payload;
      newstate.rndm =  1 + newstate.rndm;
      return newstate;
    case ACTIONS.TRIGGER_GRID:
      newstate.triggerG = action.payload;
      return newstate;
    case ACTIONS.TRIGGER_FORM:
      newstate.trigger = action.payload;
      if (action.payload === 0) {
        newstate.textDts = "";
        newstate.frmData = "";
        newstate.mainId = 0;
      }
      return newstate;
    case ACTIONS.NEWROWDATA:
      newstate.nwRow = action.payload;
      newstate.triggerG = 1;
      return newstate;
    case ACTIONS.RANDOM:
      newstate.rndm = 1 + newstate.rndm;
      return newstate;
    case ACTIONS.FORM_DATA:
      let dta: string = "";
      let fldN: any = utilities(2, action.payload, "");
      if (newstate.textDts) {
        dta = newstate.textDts + "=";
        let d: any = utilities(1, dta, fldN);
        if (d) {
          dta = d;
        } else {
          dta = "";
        }
      }
      dta += action.payload;
      newstate.textDts = dta;
      return newstate;
    case ACTIONS.SETFORM_DATA:
      newstate.frmData = action.payload;
      return newstate;
    case ACTIONS.CHECK_REQ:
      newstate.errMsg = action.payload;
      newstate.openDrwr = true;
      return newstate;
    case ACTIONS.CHECK_REQDONE:
      newstate.errMsg = [];
      newstate.openDrwr = false;
      return newstate;
    case ACTIONS.SETGID:
      if (action.payload){
        if (!newstate.gid)  {
          newstate.gid = action.payload;
          return newstate;
        }
      } else {
        newstate.gid = "";
        return newstate;
      } 
  }
};

const Hlp_grid = () => {
  //#############Constants for the State and other functions
  const [state, dispatch] = useReducer(reducer, initialState);
  const [mygid, setMyGid] = useState("")
  const onButtonClicked = (data: string) => {};
  const navigate = useNavigate();
  
  const onRowSelected = (data: string) => {
    let a : any = data.split("|")
    let id : string = a[0]
    let title : string = a[1]

    navigate(`/hlpmstr?id=${id}${'&'}scnttl=${title}`)
  };
  
  const GridLoaded = () => {
    dispatch({type: ACTIONS.TRIGGER_GRID , payload : 0})
  };
  
  const rowData: any[] = [{id:"123",scnttl:"rohit"},{id:"1234",scnttl:"soni"}];
  const [showMessage , setShowMessage] = useState<any>({message : []})
  const coldef = [
    { field: "id", hide: true, width: 0, headerName: "" },
    { field: "scnttl", hide: false, width: 150, headerName: "Screen Title" },
  ];

  useEffectOnce(() => {
    let gd: any = utilities(3, "", "");
    let gid: string = gd;
    setMyGid(gid);
  });

  const GetDataValue = (data: string, fld: string) => {
    let vl: string = getFldValue(data, fld);
    return vl;
  };


//   const GetData = () => {
//     let api: string = createGetApi(
//       "db=nodb|dll=hospdll|fnct=102",
//       state.mainId + "=sti483"
//     );
//     return useNrjAxios({ apiCall: api });
//   };

//   const ShowData = (data: any) => {
//     if (data && data.data && data.data[0] && data.data[0]["Status"]) {
//       let dt: string = data.data[0]["Data"];
//       dispatch({ type: ACTIONS.SETFORM_DATA, payload: dt });
//       //setFrmData(dt);
//     }
//   };
//   const { data: datab, refetch: refetchB } = useQuery({
//     queryKey: ["getQry", state.rndm],
//     queryFn: GetData,
//     enabled: false,
//     staleTime: Number.POSITIVE_INFINITY,
//     refetchOnWindowFocus: false,
//     refetchOnReconnect: false,
//     onSuccess: ShowData,
//   });

  const clrFunct = () => {
    let data: string = state.textDts;
    if (!data) {
      return;
    }
    data = clrFldsExcptDrpDt(data);
    dispatch({ type: ACTIONS.NEWFRMDATA, payload: data });
    dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 1 });
    setTimeout(() => {
      dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 0 });
    }, 300);
  };

  return (
    <div>
    {/* ***************************************** Title on Page **************************************************** */}
      <div
        className="w-11/12 justify-centre ml-10 pb-8"
        style={{ marginLeft: 50, marginTop: 50 }}
      >
        <NrjAgGrid
          onGridLoaded={GridLoaded}
          onRowSelected={onRowSelected}
          onButtonClicked={onButtonClicked}
          colDef={coldef}
          apiCall={""}
          rowData={rowData}
          deleteButton={""}
          deleteFldNm={""}
          newRowData={state.nwRow}
          trigger={state.triggerG}
          className="ag-theme-alpine-blue ag-theme-alpine"
        ></NrjAgGrid>
      </div>
    </div>
  );
};
export default React.memo(Hlp_grid);
