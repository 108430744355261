
import React, { Children }   from "react"
import { Navigate, useLocation, useNavigate } from "react-router-dom"
import { getCmpId } from "./utilities"
import { GetApp } from "./NrjUtility"

type Props = {
    children :JSX.Element
}
 export const NrjRequire  = ({children }:Props) : JSX.Element =>{   
    const location = useLocation();
    let cmpid = getCmpId()
    if (cmpid){
        
        let cmp = Number(cmpid)
        if (cmp > 0){
            if (!GetApp(location.pathname)){
                let newPath:string = location.pathname;
                if(newPath.indexOf('_1') > -1){
                    newPath = newPath.replace('_1',"");
                }
                else{
                    newPath = newPath + '_1';
                }
                return <Navigate to={newPath}></Navigate>        
            }
            return children;
        }
    }
    return <Navigate to ="/"></Navigate>
}

// export default NrjRequire({children})