import React, { useReducer, useState } from "react";
import axios from "axios";
import { validForm } from "../../Hooks/validForm";
import { useQuery } from "@tanstack/react-query";
import utilities, {
  GetResponseWnds,
  clrFldsExcptDrpDt,
  createGetApi,
  dataStr_ToArray,
  svLnxSrvr,
} from "../../utilities/utilities";
import { Button, SvgIcon } from "@mui/material";
import NrjAgGrid from "../../components/reusable/NrjAgGrid";
import { useNrjAxios } from "../../Hooks/useNrjAxios";

import { getFldValue } from "../../Hooks/useGetFldValue";
import { useEffectOnce } from "react-use";
import WtrInput from "../../components/reusable/nw/WtrInput";
import { Toaster } from "../../components/reusable/Toaster";
import NrjMatChp from "../../components/reusable/NrjMatChp";
import { useSearchParams } from "react-router-dom";
import SaveIcon from '@mui/icons-material/Save';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

const ACTIONS = {
  TRIGGER_GRID: "grdtrigger",
  TRIGGER_GRIDDSC: "grdtriggerdsc",
  TRIGGER_GRIDTBL: "grdtriggertbl",
  TRIGGER_GRIDCNT: "grdtriggercnt",
  TRIGGER_GRIDGDE: "grdtriggergde",
  NEWROWDATA: "newrow",
  NEWROWDATADSC: "newrowdsc",
  NEWROWDATATBL: "newrowtbl",
  NEWROWDATACNT: "newrowcnt",
  NEWROWDATAGDE: "newrowgde",
  RANDOM: "rndm",
  RANDOMDSC: "rndmdsc",
  RANDOMTBL: "rndmtbl",
  RANDOMCNT: "rndmcnt",
  RANDOMGDE: "rndmgde",
  TRIGGER_FORM: "trgfrm",
  TRIGGER_FORMDSC: "trgfrmdsc",
  TRIGGER_FORMTBL: "trgfrmtbl",
  TRIGGER_FORMCNT: "trgfrmcnt",
  TRIGGER_FORMGDE: "trgfrmgde",
  FORM_DATA: "frmdata",
  FORM_DATADSC: "frmdatadsc",
  FORM_DATATBL: "frmdatatbl",
  FORM_DATACNT: "frmdatacnt",
  FORM_DATAGDE: "frmdatagde",
  SETFORM_DATA: "setfrmdata",
  MAINID: "mnid",
  CHECK_REQ: "chckreq",
  CHECK_REQDONE: "chckreqdn",
  SETGID: "gd",
  NEWFRMDATA: "frmdatanw",
  NEWFRMDATADSC: "frmdatanwdsc",
  NEWFRMDATATBL: "frmdatanwtbl",
  NEWFRMDATACNT: "frmdatanwcnt",
  NEWFRMDATAGDE: "frmdatanwgde",
};

const initialState = {
  triggerG: 0,
  triggerGdsc: 0,
  triggerGtbl: 0,
  triggerGcnt: 0,
  triggerGgde: 0,
  nwRow: [],
  nwRowdsc: [],
  nwRowtbl: [],
  nwRowcnt: [],
  nwRowgde: [],
  rndm: 1,
  rndmdsc: 1,
  rndmtbl: 1,
  rndmcnt: 1,
  rndmgde: 1,
  trigger: 0,
  triggerdsc: 0,
  triggertbl: 0,
  triggercnt: 0,
  triggergde: 0,
  textDts: "",
  textDtsdsc: "",
  textDtstbl: "",
  textDtscnt: "",
  textDtsgde: "",
  mainId: 0,
  errMsg: [],
  openDrwr: false,
  frmData: "",
  frmDatadsc: "",
  frmDatatbl: "",
  frmDatacnt: "",
  frmDatagde: "",
  gid: "",
};

type purBill = {
  triggerG: number;
  triggerGdsc: number;
  triggerGtbl: number;
  triggerGcnt: number;
  triggerGgde: number;
  nwRow: any;
  nwRowdsc: any;
  nwRowtbl: any;
  nwRowcnt: any;
  nwRowgde: any;
  rndm: number;
  rndmdsc: number;
  rndmtbl: number;
  rndmcnt: number;
  rndmgde: number;
  trigger: number;
  triggerdsc: number;
  triggertbl: number;
  triggercnt: number;
  triggergde: number;
  textDts: string;
  textDtsdsc: string;
  textDtstbl: string;
  textDtscnt: string;
  textDtsgde: string;
  mainId: number;
  errMsg: any;
  openDrwr: boolean;
  frmData: string;
  frmDatadsc: string;
  frmDatatbl: string;
  frmDatacnt: string;
  frmDatagde: string;
  gid: string;
};

type act = {
  type: string;
  payload: any;
};

const reducer = (state: purBill, action: act) => {
  let newstate: any = { ...state };
  switch (action.type) {
    case ACTIONS.NEWFRMDATA:
      return newstate;
    case ACTIONS.NEWFRMDATADSC:
      newstate.textDtsdsc = action.payload;
      return newstate;
    case ACTIONS.NEWFRMDATATBL:
      newstate.textDtstbl = action.payload;
      return newstate;
    case ACTIONS.NEWFRMDATACNT:
      newstate.textDtscnt = action.payload;
      return newstate;
    case ACTIONS.NEWFRMDATAGDE:
      newstate.textDtsgde = action.payload;
      return newstate;
    case ACTIONS.MAINID:
      newstate.mainId = action.payload;
      // newmygid = "";
      newstate.rndm =  1 + newstate.rndm;
      return newstate;
    case ACTIONS.TRIGGER_GRID:
      newstate.triggerG = action.payload;
      return newstate;
    case ACTIONS.TRIGGER_GRIDDSC:
      newstate.triggerGdsc = action.payload;
      return newstate;
    case ACTIONS.TRIGGER_GRIDTBL:
      newstate.triggerGtbl = action.payload;
      return newstate;
    case ACTIONS.TRIGGER_GRIDCNT:
      newstate.triggerGcnt = action.payload;
      return newstate;
    case ACTIONS.TRIGGER_GRIDGDE:
      newstate.triggerGgde = action.payload;
      return newstate;
    case ACTIONS.TRIGGER_FORM:
      newstate.trigger = action.payload;
      if (action.payload === 0) {
        newstate.textDts = "";
        newstate.frmData = "";
        newstate.mainId = 0;
      }
      return newstate;
    case ACTIONS.TRIGGER_FORMDSC:
      newstate.triggerdsc = action.payload;
      if (action.payload === 0) {
        newstate.textDtsdsc = "";
        newstate.frmDatadsc = "";
        newstate.mainId = 0;
      }
      return newstate;
    case ACTIONS.TRIGGER_FORMTBL:
      newstate.triggertbl = action.payload;
      if (action.payload === 0) {
        newstate.textDtstbl = "";
        newstate.frmDatatbl = "";
        newstate.mainId = 0;
      }
      return newstate;
    case ACTIONS.TRIGGER_FORMCNT:
      newstate.triggercnt = action.payload;
      if (action.payload === 0) {
        newstate.textDtscnt = "";
        newstate.frmDatacnt = "";
        newstate.mainId = 0;
      }
      return newstate;
    case ACTIONS.TRIGGER_FORMGDE:
      newstate.triggergde = action.payload;
      if (action.payload === 0) {
        newstate.textDtsgde = "";
        newstate.frmDatagde = "";
        newstate.mainId = 0;
      }
      return newstate;
    case ACTIONS.NEWROWDATA:
      newstate.nwRow = action.payload;
      newstate.triggerG = 1;
      return newstate;
    case ACTIONS.NEWROWDATADSC:
      newstate.nwRowdsc = action.payload;
      newstate.triggerGdsc = 1;
      return newstate;
    case ACTIONS.NEWROWDATATBL:
      newstate.nwRowtbl = action.payload;
      newstate.triggerGtbl = 1;
      return newstate;
    case ACTIONS.NEWROWDATACNT:
      newstate.nwRowcnt = action.payload;
      newstate.triggerGcnt = 1;
      return newstate;
    case ACTIONS.NEWROWDATAGDE:
      newstate.nwRowgde = action.payload;
      newstate.triggerGgde = 1;
      return newstate;
    case ACTIONS.RANDOM:
      newstate.rndm = 1 + newstate.rndm;
      return newstate;
    case ACTIONS.RANDOMDSC:
      newstate.rndmdsc = 1 + newstate.rndmdsc;
      return newstate;
    case ACTIONS.RANDOMTBL:
      newstate.rndmtbl = 1 + newstate.rndmtbl;
      return newstate;
    case ACTIONS.RANDOMCNT:
      newstate.rndmcnt = 1 + newstate.rndmcnt;
      return newstate;
    case ACTIONS.RANDOMGDE:
      newstate.rndmgde = 1 + newstate.rndmgde;
      return newstate;
    case ACTIONS.FORM_DATA:
      let dta: string = "";
      let fldN: any = utilities(2, action.payload, "");
      if (newstate.textDts) {
        dta = newstate.textDts + "=";
        let d: any = utilities(1, dta, fldN);
        if (d) {
          dta = d;
        } else {
          dta = "";
        }
      }
      dta += action.payload;
      newstate.textDts = dta;
      return newstate;
    case ACTIONS.FORM_DATADSC:
      let dsc: string = "";
      let flddsc: any = utilities(2, action.payload, "");
      if (newstate.textDtsdsc) {
        dsc = newstate.textDtsdsc + "=";
        let d: any = utilities(1, dsc, flddsc);
        if (d) {
          dsc = d;
        } else {
          dsc = "";
        }
      }
      dsc += action.payload;
      newstate.textDtsdsc = dsc;
      return newstate;
    case ACTIONS.FORM_DATACNT:
      let cnt: string = "";
      let fldcnt: any = utilities(2, action.payload, "");
      if (newstate.textDtscnt) {
        cnt = newstate.textDtscnt + "=";
        let d: any = utilities(1, cnt, fldcnt);
        if (d) {
          cnt = d;
        } else {
          cnt = "";
        }
      }
      cnt += action.payload;
      newstate.textDtscnt = cnt;
      return newstate;

    case ACTIONS.FORM_DATAGDE:
      let gde: string = "";
      let fldgde: any = utilities(2, action.payload, "");
      if (newstate.textDtsgde) {
        // let cntg : string = newstate.textDtsgde + "=";
        gde = newstate.textDtsgde + "=";
        let d: any = utilities(1, gde, fldgde);
        if (d) {
          gde = d;
        } else {
          gde = "";
        }
      }
      gde += action.payload;
      newstate.textDtsgde = gde;
      return newstate;

    case ACTIONS.FORM_DATATBL:
      let tbl: string = "";
      let fldtbl: any = utilities(2, action.payload, "");
      if (newstate.textDtstbl) {
        tbl = newstate.textDtstbl + "=";
        let d: any = utilities(1, tbl, fldtbl);
        if (d) {
          tbl = d;
        } else {
          tbl = "";
        }
      }
      tbl += action.payload;
      newstate.textDtstbl = tbl;
      return newstate;

    case ACTIONS.SETFORM_DATA:
      newstate.frmData = action.payload;
      return newstate;
    case ACTIONS.CHECK_REQ:
      newstate.errMsg = action.payload;
      newstate.openDrwr = true;
      return newstate;
    case ACTIONS.CHECK_REQDONE:
      newstate.errMsg = [];
      newstate.openDrwr = false;
      return newstate;
    case ACTIONS.SETGID:
      if (action.payload){
        if (!newstate.gid)  {
          newstate.gid = action.payload;
          return newstate;
        }
      } else {
        newstate.gid = "";
        return newstate;
      }
      
  }
};

//const [state, dispatch] = useReducer(reducer, initialState);

const Hlp_mstr = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [mygid, setMyGid] = useState("")
  const [searchParams, setSearchParams] = useSearchParams();

  const reqFlds = [
    { fld: "scnttl", msg: "Enter Title of Page", chck: "length" },
  ];
  const coldef = [
    { field: "id", hide: true, width: 0, headerName: "" },
    { field: "scnttl", hide: false, width: 150, headerName: "Screen Title" },
  ];

  /* ***********************************   Desciben    ********************************************* */
  
  const onRowSelecteddsc = (data: string) => {};
  const GridLoadeddsc = () => {};
  const rowDatadsc: any[] = [];
  const [showMessagedsc, setShowMessagedsc] = useState<any>({ message: [] });
  const reqFldsdsc = [
    { fld: "scndsc", msg: "Enter Point of Description", chck: "length" },
  ];
  const coldefdsc = [
    { field: "id", hide: true, width: 0, headerName: "" },
    {
      field: "scndsc",
      hide: false,
      width: 750,
      headerName: "Screen Description",
    },
  ];

  const HandleSaveClickDsc = () => {
  let api: string = state.textDtsdsc;
    api = svLnxSrvr(mygid, api, "", "nodb", "admn0hlp_dscp", "0", "2");
    return useNrjAxios({ apiCall: api });
  };

  const SaveClickDsc = () => {
    let api: string = state.textDtsdsc;
    let msg: any = validForm(api, reqFldsdsc);
    if (msg && msg[0]) {
      setShowMessagedsc({ message: msg, type: "error" });
      dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
      return;
    }
    refetchdsc();
  };

  const svdQrydsc = (datadsc: any) => {
    let ech: any[];
    let str: string = GetResponseWnds(datadsc);
    if (str) {
      ech = str.split("][");
      if (ech && ech[0]) {
        if (Number(ech[0]) > -1) {
          setShowMessagedsc({
            message: ["Data Saved Successfully"],
            type: "success",
          });
          //  Uncomment for the new row in grid
          let ary: any = dataStr_ToArray(state.textDtsdsc);
          dispatch({ type: ACTIONS.NEWROWDATADSC, payload: ary });
          setTimeout(function () {
            dispatch({ type: ACTIONS.TRIGGER_GRIDDSC, payload: 0 });
          }, 1000);
        }
      }
    }
    clrFunctdsc();
  };
  const { data: datadsc, refetch: refetchdsc } = useQuery({
    queryKey: ["svDsc", state.rndmdsc],
    queryFn: HandleSaveClickDsc,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: svdQrydsc,
  });

  const clrFunctdsc = () => {
    let data: string = state.textDtsdsc;
    if (!data) {
      return;
    }
    data = clrFldsExcptDrpDt(data);
    dispatch({ type: ACTIONS.NEWFRMDATADSC, payload: data });
    dispatch({ type: ACTIONS.TRIGGER_FORMDSC, payload: 1 });
    setTimeout(() => {
      dispatch({ type: ACTIONS.TRIGGER_FORMDSC, payload: 0 });
    }, 300);
  };

  /******************************************  Desciben code  ********************************************** */
  /* *********************************************** Table code ****************************************************** */
const onRowSelectedtbl = (data: string) => {};
  const GridLoadedtbl = () => {};
  const rowDatatbl: any[] = [];
  const [showMessagetbl, setShowMessagetbl] = useState<any>({ message: [] });
  const reqFldstbl = [
        { fld: "hdrttl", msg: "Enter Header", chck: "length" },
        { fld: "hdrdsc", msg: "Enter Header", chck: "length" },
      ];
      const coldeftbl = [
        { field: "id", hide: true, width: 0, headerName: "" },
        { field: "hdrttl", hide: false, width: 200, headerName: "Table Header" },
        { field: "hdrdsc", hide: false, width: 750, headerName: "Table Point" },
      ];

  const HandleSaveClicktbl = () => {
    let api: string = state.textDtstbl;
    api = svLnxSrvr(mygid, api, "", "nodb", "admn0hlp_tbl", "0", "3");
    return useNrjAxios({ apiCall: api });
  };

  const SaveClickTbl = () => {
    let api: string = state.textDtstbl;
    let msg: any = validForm(api, reqFldstbl);
    if (msg && msg[0]) {
      setShowMessagetbl({ message: msg, type: "error" });
      dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
      return;
    }
    refetchtbl();
  };

  const svdQrytbl = (datatbl: any) => {
    let ech: any[];
    let str: string = GetResponseWnds(datatbl);
    if (str) {
        ech = str.split("][");
        if (ech && ech[0]) {
        if (Number(ech[0]) > -1) {
            setShowMessagetbl({
                message: ["Data Saved Successfully"],
                type: "success",
            });
            
            //  Uncomment for the new row in grid
            let ary: any = dataStr_ToArray(state.textDtstbl);
            dispatch({ type: ACTIONS.NEWROWDATATBL, payload: ary });
            setTimeout(function () {
                dispatch({ type: ACTIONS.TRIGGER_GRIDTBL, payload: 0 });
            }, 1000);
        }
        // dispatch({ type: ACTIONS.MAINID, payload: Number(ech[1]) });
        // setTimeout(function () {
            //   dispatch({ type: ACTIONS.CHECK_REQDONE, payload: "" });
            // }, 1000);
        }
    }
    console.trace()
    clrFuncttbl();
  };
  const { data: datatbl, refetch: refetchtbl } = useQuery({
    queryKey: ["svtblTbl", state.rndmtbl],
    queryFn: HandleSaveClicktbl,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: svdQrytbl,
  });

  const clrFuncttbl = () => {
    let data: string = state.textDtstbl;
    if (!data) {
      return;
    }
    data = clrFldsExcptDrpDt(data);
    dispatch({ type: ACTIONS.NEWFRMDATATBL, payload: data });
    dispatch({ type: ACTIONS.TRIGGER_FORMTBL, payload: 1 });
    setTimeout(() => {
      dispatch({ type: ACTIONS.TRIGGER_FORMTBL, payload: 0 });
    }, 300);
  };
  /* *************************************** table code *******************************************/
  /* *******************************************  Cntr   ********************************************************* */
  const onRowSelectedcnt = (data: string) => {};
  const GridLoadedcnt = () => {};
  const rowDatacnt: any[] = [];
  const [showMessagecnt, setShowMessagecnt] = useState<any>({ message: [] });
  const reqFldscnt = [
    { fld: "pgcntr", msg: "Enter Control", chck: "length" },
    { fld: "cntdsc", msg: "Enter Header", chck: "length" },
  ];
  const coldefcnt = [
    { field: "id", hide: true, width: 0, headerName: "" },
    { field: "pgcntr", hide: false, width: 150, headerName: "Control on Page" },
    { field: "cntdsc", hide: false, width: 150, headerName: "Description" },
  ];

  const HandleSaveClickCnt = () => {
    let api: string = state.textDtscnt;
    api = svLnxSrvr(mygid, api, "", "nodb", "admn0hlp_cntrl", "0", "4");
    return useNrjAxios({ apiCall: api });
  };

  const SaveClickCnt = () => {
    let api: string = state.textDtscnt;
    let msg: any = validForm(api, reqFldscnt);
    if (msg && msg[0]) {
      setShowMessagecnt({ message: msg, type: "error" });
      dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
      return;
    }
    refetchcnt();
  };

  const svdQrycnt = (datacnt: any) => {
    let ech: any[];
    let str: string = GetResponseWnds(datacnt);
    if (str) {
      ;
      ech = str.split("][");
      if (ech && ech[0]) {
        if (Number(ech[0]) > -1) {
          setShowMessagecnt({
            message: ["Data Saved Successfully"],
            type: "success",
          });

          //  Uncomment for the new row in grid
          let ary: any = dataStr_ToArray(state.textDtscnt);
          dispatch({ type: ACTIONS.NEWROWDATACNT, payload: ary });
          setTimeout(function () {
            dispatch({ type: ACTIONS.TRIGGER_GRIDCNT, payload: 0 });
          }, 1000);
        }
        // dispatch({ type: ACTIONS.MAINID, payload: Number(ech[1]) });
        // setTimeout(function () {
        //   dispatch({ type: ACTIONS.CHECK_REQDONE, payload: "" });
        // }, 1000);
      }
    }
    clrFunctcnt();
  };
  const { data: datacnt, refetch: refetchcnt } = useQuery({
    queryKey: ["svCnt", state.rndmcnt],
    queryFn: HandleSaveClickCnt,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: svdQrycnt,
  });

  const clrFunctcnt = () => {
    let data: string = state.textDtscnt;
    if (!data) {
      return;
    }
    data = clrFldsExcptDrpDt(data);
    dispatch({ type: ACTIONS.NEWFRMDATACNT, payload: data });
    dispatch({ type: ACTIONS.TRIGGER_FORMCNT, payload: 1 });
    setTimeout(() => {
      dispatch({ type: ACTIONS.TRIGGER_FORMCNT, payload: 0 });
    }, 300);
  };
  /* ******************************************** cnt code ****************************************************** */
  /* *******************************************  ged   ********************************************************* */
  const onRowSelectedgde = (data: string) => {};
  const GridLoadedgde = () => {};
  const rowDatagde: any[] = [];
  const [showMessagegde, setShowMessagegde] = useState<any>({ message: [] });

  const reqFldsgde = [
    { fld: "usggd", msg: "Enter Guide Line for Usage", chck: "length" },
  ];

  const coldefgde = [
    { field: "id", hide: true, width: 0, headerName: "" },
    { field: "usggd", hide: false, width: 150, headerName: "Guide Line" },
  ];

  const HandleSaveClickgde = () => {
    let api: string = state.textDtsgde;
    api = svLnxSrvr(mygid, api, "", "nodb", "admn0hlp_gde", "0", "5");
    return useNrjAxios({ apiCall: api });
  };

  const SaveClickgde = () => {
    let api: string = state.textDtsgde;
    let msg: any = validForm(api, reqFldsgde);
    if (msg && msg[0]) {
      setShowMessagegde({ message: msg, type: "error" });
      dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
      return;
    }
    refetchgde();
  };

  const svdQrygde = (datagde: any) => {
    let ech: any[];
    let str: string = GetResponseWnds(datagde);
    if (str) {
      ;
      ech = str.split("][");
      if (ech && ech[0]) {
        if (Number(ech[0]) > -1) {
          setShowMessagegde({
            message: ["Data Saved Successfully"],
            type: "success",
          });
          //  Uncomment for the new row in grid
          let ary: any = dataStr_ToArray(state.textDtsgde);
          dispatch({ type: ACTIONS.NEWROWDATAGDE, payload: ary });
          setTimeout(function () {
            dispatch({ type: ACTIONS.TRIGGER_GRIDGDE, payload: 0 });
          }, 1000);
        }
      }
    }
    clrFunctgde();
  };
  const { data: datagde, refetch: refetchgde } = useQuery({
    queryKey: ["svGde", state.rndmgde],
    queryFn: HandleSaveClickgde,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,

    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: svdQrygde,
  });

  const clrFunctgde = () => {
    let data: string = state.textDtsgde;
    if (!data) {
      return;
    }
    data = clrFldsExcptDrpDt(data);
    dispatch({ type: ACTIONS.NEWFRMDATAGDE, payload: data });
    dispatch({ type: ACTIONS.TRIGGER_FORMGDE, payload: 1 });
    setTimeout(() => {
      dispatch({ type: ACTIONS.TRIGGER_FORMGDE, payload: 0 });
    }, 300);
  };
  /* ******************************************** gde code ****************************************************** */

  useEffectOnce(() => {
    let gd: any = utilities(3, "", "");
    let gid: string = gd;
    setMyGid(gid)
    let id = searchParams.get("id");
    if (id){
      GetDataBySrec()
    }
  });
  
  
  const GetDataBySrec = () =>{
    let title = searchParams.get("scnttl");
    dispatch({type:ACTIONS.SETFORM_DATA , payload : `scnttl][${title}`})
  }

  const GetDataValue = (data: string, fld: string) => {
    let vl: string = getFldValue(data, fld);
    return vl;
  };
  const onChangeDts = (data: string) => {
    dispatch({ type: ACTIONS.FORM_DATA, payload: data });
  };
  

  const onChangeDtsdsc = (datadsc: string) => {
    dispatch({ type: ACTIONS.FORM_DATADSC, payload: datadsc });
  };

  const onChangeDtstbl = (datatbl: string) => {
    dispatch({ type: ACTIONS.FORM_DATATBL, payload: datatbl });
  };

  const onChangeDtscnt = (datacnt: string) => {
    dispatch({ type: ACTIONS.FORM_DATACNT, payload: datacnt });
  };
  const onChangeDtsgde = (data: string) => {
    dispatch({ type: ACTIONS.FORM_DATAGDE, payload: data });
  };

  const HandleSaveClick = () => {
    let api: string = state.textDts;
    /* Uncomment for the new row in grid
        let ary : any = dataStr_ToArray(api);
        dispatch({type:ACTIONS.NEWROWDATA, payload : ary});
        setTimeout(function(){
          dispatch({type:ACTIONS.TRIGGER_GRID, payload : 0});
        },1500);
        */
    api = svLnxSrvr(
      mygid,
      api,
      "",
      "fd_inv",
      "admn0hlp_mstr",
      state.mainId,
      "1"
    );
    return useNrjAxios({ apiCall: api });
  };

  const SaveClick = () => {
    let api: string = state.textDts;
    let msg: any = validForm(api, reqFlds);
    if (msg && msg[0]) {
      dispatch({ type: ACTIONS.CHECK_REQ, payload: msg });
      return;
    }
    refetch();
  };

  const svdQry = (data: any) => {
    let ech: any[];

    let str: string = GetResponseWnds(data);
    if (str) {
      ech = str.split("#");
      if (ech && ech[0]) {
        if (Number(ech[0]) > -1) {
        //   setShowMessagetbl({
        //     message: ["Data Saved Successfully"],
        //     type: "success",
        //   });
        }
        dispatch({ type: ACTIONS.MAINID, payload: Number(ech) });

        // setTimeout(function () {
        //   dispatch({ type: ACTIONS.CHECK_REQDONE, payload: "" });
        // }, 1000);
      }
    }
  };
  const { data, refetch } = useQuery({
    queryKey: ["svQry",  state.rndm],
    queryFn: HandleSaveClick,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: svdQry,
  });

  const GetData = () => {
    let api: string = createGetApi(
      "db=nodb|dll=hospdll|fnct=102",
      state.mainId + "=sti483"
    );
    return useNrjAxios({ apiCall: api });
  };

  const ShowData = (data: any) => {
    if (data && data.data && data.data[0] && data.data[0]["Status"]) {
      let dt: string = data.data[0]["Data"];
      dispatch({ type: ACTIONS.SETFORM_DATA, payload: dt });
      //setFrmData(dt);
    }
  };
  const { data: datab, refetch: refetchB } = useQuery({
    queryKey: ["getQry", state.rndm],
    queryFn: GetData,
    enabled: false,
    staleTime: Number.POSITIVE_INFINITY,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: ShowData,
  });

  // const clrFunct = () => {
  //   let data: string = state.textDts;
  //   if (!data) {
  //     return;
  //   }
  //   data = clrFldsExcptDrpDt(data);
  //   dispatch({ type: ACTIONS.NEWFRMDATA, payload: data });
  //   dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 1 });
  //   setTimeout(() => {
  //     dispatch({ type: ACTIONS.TRIGGER_FORM, payload: 0 });
  //   }, 300);
  // };

  return (

    
    <div>
      {/* ***************************************** Title **************************************************** */}
      <div>
        <WtrInput
          Label="Screen Title"
          fldName="scnttl"
          idText="txtscnttl"
          onChange={onChangeDts}
          dsabld={false}
          callFnFocus=""
          dsbKey={false}
          upprCase={false}
          validateFn=""
          allowNumber={false}
          selectedValue={state.frmData}
          clrFnct={state.trigger}
          speaker={"Enter Title of Page"}
          IAmRequired={reqFlds}
          delayClose={1000}
          placement="right"
          ClssName=""
        ></WtrInput>
      </div>
      {/* ***************************************** dsc **************************************************** */}
      <div>
        <NrjMatChp title="Description of Page" color="#91d5ef"></NrjMatChp>
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 mt-3 lg:grid-cols-3">
        <div>
          <WtrInput
            Label="Screen Description"
            fldName="scndsc"
            idText="txtscndsc"
            onChange={onChangeDtsdsc}
            dsabld={false}
            callFnFocus=""
            dsbKey={false}
            upprCase={false}
            validateFn=""
            allowNumber={false}
            selectedValue={state.frmDatadsc}
            clrFnct={state.triggerdsc}
            speaker={"Enter Point of Description"}
            IAmRequired={reqFldsdsc}
            delayClose={1000}
            placement="right"
            ClssName=""
          ></WtrInput>
        </div>
        <div className="" style={{ marginTop: "34px" }}>
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            onClick={SaveClickDsc}
            // startIcon={}
          >
            Add
          </Button>
        </div>
      </div>

      {showMessagedsc && showMessagedsc.message.length != 0 ? (
        <div className="relative py-2">
          <Toaster data={showMessagedsc} className={""}></Toaster>
        </div>
      ) : (
        <></>
      )}
      <div
        className="w-11/12 justify-centre ml-10"
        style={{ marginLeft: 50, marginTop: 50 }}
      >
        <NrjAgGrid
          onGridLoaded={GridLoadeddsc}
          onRowSelected={onRowSelecteddsc}
          colDef={coldefdsc}
          apiCall={""}
          rowData={rowDatadsc}
          deleteButton={""}
          deleteFldNm={""}
          newRowData={state.nwRowdsc}
          trigger={state.triggerGdsc}
          className="ag-theme-alpine-blue ag-theme-alpine"
          height={200}
        ></NrjAgGrid>
      </div>
      {/* ***************************************** Table **************************************************** */}
      <div className="mt-10">
        <NrjMatChp title="Table on the Page" color="#91d5ef"></NrjMatChp>
      </div>

      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10">
        <div>
          <WtrInput
            Label="Table Header"
            fldName="hdrttl"
            idText="txthdrttl"
            onChange={onChangeDtstbl}
            dsabld={false}
            callFnFocus=""
            dsbKey={false}
            upprCase={false}
            validateFn=""
            allowNumber={false}
            selectedValue={state.frmDatatbl}
            clrFnct={state.triggertbl}
            speaker={"Enter Header"}
            IAmRequired={reqFldstbl}
            delayClose={1000}
            placement="right"
            ClssName=""
            ></WtrInput>
        </div>

        <div>
          <WtrInput
            Label="Table Point"
            fldName="hdrdsc"
            idText="txthdrdsc"
            onChange={onChangeDtstbl}
            dsabld={false}
            callFnFocus=""
            dsbKey={false}
            upprCase={false}
            validateFn=""
            allowNumber={false}
            selectedValue={state.frmDatatbl}
            clrFnct={state.triggertbl}
            speaker={"Enter Header"}
            IAmRequired={reqFldstbl}
            delayClose={1000}
            placement="bottom"
            ClssName=""
          ></WtrInput>
        </div>
        <div className="" style={{ marginTop: "34px" }}>
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            onClick={SaveClickTbl}
            // startIcon={}
          >
            Add
          </Button>
        </div>
      </div>
      {showMessagetbl && showMessagetbl.message.length != 0 ? (
          <div className="relative py-2">
          <Toaster data={showMessagetbl} className={""}></Toaster>
        </div>
      ) : (
          <></>
          )}

      <div
        className="w-11/12 justify-centre ml-10"
        style={{ marginLeft: 50, marginTop: 50 }}
        >
        <NrjAgGrid
          onGridLoaded={GridLoadedtbl}
          onRowSelected={onRowSelectedtbl}
          colDef={coldeftbl}
          apiCall={""}
          rowData={rowDatatbl}
          deleteButton={""}
          deleteFldNm={""}
          newRowData={state.nwRowtbl}
          trigger={state.triggerGtbl}
          className="ag-theme-alpine-blue ag-theme-alpine"
          height={250}
          ></NrjAgGrid>
      </div>
{/* ***************************************** Usage Guideline **************************************************** */}
      <div className="mt-10">
        <NrjMatChp title="Usage Guideline" color="#91d5ef"></NrjMatChp>
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mt-10">
        <div>
          <WtrInput
            Label="Guide Line"
            fldName="usggd"
            idText="txtusggd"
            onChange={onChangeDtsgde}
            dsabld={false}
            callFnFocus=""
            dsbKey={false}
            upprCase={false}
            validateFn=""
            allowNumber={false}
            selectedValue={state.frmDatagde}
            clrFnct={state.triggerGgde}
            speaker={"Enter Guide Line for Usage"}
            IAmRequired={reqFldsgde}
            delayClose={1000}
            placement="right"
            ClssName=""
          ></WtrInput>
        </div>

        <div className="" style={{ marginTop: "34px" }}>
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            onClick={SaveClickgde}
            // startIcon={}
          >
            Add
          </Button>
        </div>
      </div>
      {showMessagegde && showMessagegde.message.length != 0 ? (
        <div className="relative py-2">
          <Toaster data={showMessagegde} className={""}></Toaster>
        </div>
      ) : (
        <></>
      )}
      <div
        className="w-11/12 justify-centre ml-10"
        style={{ marginLeft: 50, marginTop: 50 }}
      >
        <NrjAgGrid
          onGridLoaded={GridLoadedgde}
          onRowSelected={onRowSelectedgde}
          colDef={coldefgde}
          apiCall={""}
          rowData={rowDatagde}
          deleteButton={""}
          deleteFldNm={"iddel"}
          newRowData={state.nwRowgde}
          trigger={state.triggerGgde}
          className="ag-theme-alpine-blue ag-theme-alpine"
          // deleteApi={""}
          height={250}
        ></NrjAgGrid>
      </div>
{/* ***************************************** Controls on Page **************************************************** */}
      <div className="mt-10">
        <NrjMatChp title="Controls on Page" color="#91d5ef"></NrjMatChp>
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10">
        <div>
          <WtrInput
            Label="Control on Page"
            fldName="pgcntr"
            idText="txtpgcntr"
            onChange={onChangeDtscnt}
            dsabld={false}
            callFnFocus=""
            dsbKey={false}
            upprCase={false}
            validateFn=""
            allowNumber={false}
            selectedValue={state.frmDatacnt}
            clrFnct={state.triggercnt}
            speaker={"Enter Control"}
            IAmRequired={reqFldscnt}
            delayClose={1000}
            placement="right"
            ClssName=""
          ></WtrInput>
        </div>

        <div>
          <WtrInput
            Label="Description"
            fldName="cntdsc"
            idText="txtcntdsc"
            onChange={onChangeDtscnt}
            dsabld={false}
            callFnFocus=""
            dsbKey={false}
            upprCase={false}
            validateFn=""
            allowNumber={false}
            selectedValue={state.frmDatacnt}
            clrFnct={state.triggercnt}
            speaker={"Enter Header"}
            IAmRequired={reqFldscnt}
            delayClose={1000}
            placement="bottom"
            ClssName=""
          ></WtrInput>
        </div>
        <div className="" style={{ marginTop: "34px" }}>
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            // startIcon={}
            onClick={SaveClickCnt}
          >
            Add
          </Button>
        </div>
      </div>
      {showMessagecnt && showMessagecnt.message.length != 0 ? (
        <div className="relative py-2">
          <Toaster data={showMessagecnt} className={""}></Toaster>
        </div>
      ) : (
        <></>
      )}

      <div
        className="w-11/12 justify-centre ml-10"
        style={{ marginLeft: 50, marginTop: 50 }}
      >
        <NrjAgGrid
          onGridLoaded={GridLoadedcnt}
          onRowSelected={onRowSelectedcnt}
          colDef={coldefcnt}
          apiCall={""}
          rowData={rowDatacnt}
          deleteButton={""}
          deleteFldNm={""}
          newRowData={state.nwRowcnt}
          trigger={state.triggerGcnt}
          className="ag-theme-alpine-blue ag-theme-alpine"
          deleteApi={""}
          height={250}
        ></NrjAgGrid>
      </div>

      <div className="flex justify-center pb-8" style={{marginTop:"34px"}}>
        <div className="mx-2">
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            onClick={SaveClick}
            startIcon={<SaveIcon />}
            // startIcon={}
          >
            Save
          </Button>
        </div>
        <div className="mx-2">
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            startIcon={<PersonAddIcon/>}
            onClick={SaveClick}
            // startIcon={}
          >
            Add
          </Button>
        </div>
        <div className="mx-2">
          <Button
            size="medium"
            style={{ backgroundColor: "#0F8EC6" }}
            variant="contained"
            color="success"
            onClick={SaveClick}
            // startIcon={}
          >
            Print
          </Button>
        </div>
      </div>
    </div>
  );
};
export default React.memo(Hlp_mstr);
