import { configureStore } from '@reduxjs/toolkit'
import dailyReportReducer from './feature/DailyReport/dailyReportSlice';
import dailySummaryReducer from './feature/DailySummary/dailySummarySlice';
import dailySummaryFactoryReducer from './feature/DailySummaryFactory/dailySummaryFactorySlice';
import monthlyReportReducer from './feature/MonthlyReport/dailyReportSlice';


export const store = configureStore({
  reducer: {
    dailyReport: dailyReportReducer,
    dailySummary: dailySummaryReducer,
    dailySummaryFactory: dailySummaryFactoryReducer,
    monthlyReport: monthlyReportReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch