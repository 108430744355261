import { nrjAxios } from "../Hooks/useNrjAxios";
import { createGetApi, dataStr_ToArray, getCmpId } from "./utilities";
let applicationConfiguration:any = {
        UpperCase : '0',
        SaveNow : '0',
        shwbd : "0",
        AllwPrntLbl: '0'
};

export const setApplicationConfiguration = (data:any)=>{
    applicationConfiguration = data;
}

export const getApplicationConfiguration = ()=>{
    return applicationConfiguration;
}

export const isHspLogin = () =>{
    let lgn : string = sessionStorage.getItem("isHospitalAggregator") || "";
    if (lgn == "true"){
        return true;
    }
    return false;
};

export const isStateLogin = ()=>{
  const cmpId: string = getCmpId();
  let returnValue: boolean = false;

  if(cmpId && cmpId.length){
    returnValue = cmpId == '2434712' ? true : false;
  }
  return returnValue;
}

export const allwPrntLbl = ()=>{
    let lgn : string = sessionStorage.getItem("AllwPrntLbl") || "0";
    if (lgn == "1"){
        return true;
    }

    return false;
    

}

export const upprCase = ()=>{
    let lgn : string = sessionStorage.getItem("UpperCase") || "0";
    if (lgn == "1"){
        return true;
    }
    return false;

}

export const setConfig = (data : string)=>{
    let tempApplicationConfig = {
        UpperCase : '0',
        SaveNow : '0',
        shwbd : "0",
        AllwPrntLbl: '0'
    };
    if (!data){
        return;
    }
    let ary : any = dataStr_ToArray(data);
    sessionStorage.removeItem("UpperCase");
    sessionStorage.removeItem("AllwPrntLbl");
    sessionStorage.removeItem("SaveNow");

    if (ary[0]['prntlbl']){
        if (ary[0]['prntlbl'] == "1"){
            sessionStorage.setItem("AllwPrntLbl", "1");
            tempApplicationConfig.AllwPrntLbl = '1';
        }
    }

    if (ary[0]['ucsi']){
        if (ary[0]['ucsi'] == "1"){
            sessionStorage.setItem("UpperCase", "1");
            tempApplicationConfig.UpperCase = '1';
        }
    }

    if(ary[0]['svn']){
        if(ary[0]['svn'] == '1'){
            sessionStorage.setItem("SaveNow", "1")
            tempApplicationConfig.SaveNow = '1';
        }
    }
    if(ary[0]['shwbd']){
        if(ary[0]['shwbd'] == '1'){
            sessionStorage.setItem("shwbd", "1")
            tempApplicationConfig.shwbd = '1';
        }
    }

    if (ary[0]['cbwtf']){
        sessionStorage.setItem("dsplnm", ary[0]["cbwtf"]);
    }
    
    setApplicationConfiguration(tempApplicationConfig);
}

export const showFieldInColdef = function (x: string): boolean {
    if(applicationConfiguration[x] == '1'){
        return true
    }
    if (isStateLogin()){
        return true
    }
    return false;
  };

export async function fetchAppConfiguration(): Promise<any> {
    let api: string = createGetApi("dll=hospdll|fnct=102|db=nodb", "0=slc962")
    return nrjAxios({ apiCall: api });
}

export const clrNAValue = (ary : any, cntr : number)=>{
    let i: number = 0;
      while (i < ary.length) {
        // ary[i]["cbwtfid"] = i + 1 + cntr;
        if (ary[i]["addra"] == "NA") {
          ary[i]["addra"] = "";
        }
        if (ary[i]["addrb"] == "NA") {
          ary[i]["addrb"] = "";
        }
        if (ary[i]["addrc"] == "NA") {
          ary[i]["addrc"] = "";
        }
        ary[i]["_id"] = "";
        if (ary[i]['nobd'])    {
            if (Number(ary[i]['nobd'])<0){
                ary[i]['nobd'] = 0
            }
        }
        i += 1;
      }
      return ary;
}