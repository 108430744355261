import { useEffect, useState } from "react";

type Tst = {
  data: any,
  className?: string
}

export const Toaster = (props: Tst) => {
  let cls: string = props.className ? props.className : "";
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [className, setClassName] = useState<string>(
    `border rounded-lg flex justify-center items-center p-4 ${cls}`
  );

  useEffect(() => {
    if (props.data && props.data.message.length > 0) {
      setShowMessage(true);
    }
  }, [props.data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowMessage(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, [showMessage]);

  return (
    <>
      {showMessage && props.data &&
        props.data.message.length &&
        props.data.type === "error" && (
          <div
            className={`fixed top-12 right-4 border-red-600 text-red-800 ${className}`}
            style={{
              textAlign: "center",
              zIndex: 1001,
              backgroundColor: "rgba(255, 0, 0, 0.3)",
              color: "#b71c1c", // Darker red color for better contrast
            }}
          >
            <ul>
              {props.data.message.map((res: any, index: number) => (
                <li key={index}>
                  {index + 1} : {res}
                </li>
              ))}
            </ul>
          </div>
        )}
      {showMessage && props.data &&
        (props.data.message.length || props.data.message.message) &&
        props.data.type === "success" && (
          <div
            className={`fixed top-12 right-4 border-green-600 text-black font-semibold ${className}`}
            style={{
              backgroundColor: "rgba(0, 255, 0, 0.3)",
              zIndex: 1001,
              textAlign: "center",
            }}
          >
            <ul>
              {props.data.message.map((res: any, index: number) => (
                <li key={index}>
                  {index + 1} : {res}
                </li>
              ))}
            </ul>
          </div>
        )}
    </>
  );
};
