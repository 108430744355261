import path from "path";
//import { useLocation, useNavigate } from "react-router";

export const SetApp =(frmt:string)=>{
    sessionStorage.setItem("formate",frmt)

} 

export const GetApp = (pathName?:string)=>{
   
    pathName = pathName? pathName : ''
   
    let f:string = sessionStorage.getItem("formate")||"1"
    if (f == "_1"){
        if (pathName.indexOf("_1")> -1){
            return true
        } else {
            return false
        }
    } else {
        if (pathName.indexOf("_1")> -1){
            return false
        } else {
            return true
        }
    }
    
}