import React from 'react';
import footerImage from '../../assets/illustration.png';


const Footer = (props:any)=> {
 

  return (
    <>
      <footer className="bg-white rounded-lg mt-9 shadow dark:bg-gray-800 relative" style={{top:'100px'}}>
      <div className="p-7 rounded text-white" style={{ background: 'linear-gradient(90.29deg, #9CCD85 0%, #C7DD70 100%)' }}>
        
        <div className="py-7 flex items-center justify-center" style={{fontFamily: 'Righteous', fontSize: '24px'}}> Together we can bring change !!</div>
        <div className="absolute right-0" style={{top:'-10px'}}>
          <img src={footerImage} alt="" style={{ height: '50%' }} />
        </div>
      </div>
      </footer>
    </>
  )
}
export default React.memo(Footer);