import React, { Component } from 'react'
import axios from 'axios';

export default (props: any) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    let clsName :  string = props.className ? props.className  : "";
    const buttonClicked = () => {
       
        
    };
  
    const delt = (delApi : string)=>{
       
    }  


    return (
      <span>
        <button onClick={() => buttonClicked()} className={clsName}>{props.buttonText}</button>
      </span>
    );
  };